
import { defineComponent, ref } from 'vue'
import TopBar from '@/components/TopBar.vue'
import Footer from '@/components/Footer.vue'
import { getNewsList } from '@/api/information'

export default defineComponent({
  name: 'Products',
  components: {
    TopBar,
    Footer,
  },

  setup () {
    const more = ref('>')
    const menuTag = ref(16)
    const baseUrl = ref('https://www.otimes.com/otimes_zw/img/')
    const params = ref({
      page_id: 1,
      page_limit: 5
    })
    const newsList = ref([])
    const total = ref(0)
    const getInformationList = async () => {
      const res: any = await getNewsList({
        type_id: menuTag.value,
        page_id: params.value.page_id,
        page_limit: params.value.page_limit
      })
      if (res.meta.success) {
        newsList.value = res.data.list
        total.value = res.data.count
      }
    }
    const handleCurrentChange = (num) => {
      params.value.page_id = num
      getInformationList()
    }
    const getIndexOf = (el) => {
      if (el.indexOf('http') == -1) {
        return 0
      } else {
        return 1
      }
    }
    getInformationList()
    return {
      more,
      menuTag,
      baseUrl,
      params,
      newsList,
      total,
      getInformationList,
      handleCurrentChange,
      getIndexOf
    }
  }
});
